<script setup lang="ts">
import {
    getSmallestThumbnailUrl,
    getProductRoute,
} from '@shopware-pwa/helpers-next';
import { LineItem } from '@shopware-pwa/types';
import { useGtmTrackingWithProduct } from '~/composables/useGTMTracking';

const props = defineProps<{
    cartItem: LineItem;
    maxQty?: number;
}>();

const { cartItem } = toRefs(props);

const isLoading = ref(false);
const { codeErrorsNotification } = useCartNotification();

const {
    itemOptions,
    removeItem,
    itemTotalPrice,
    itemQuantity,
    isPromotion,
    changeItemQuantity,
} = useCartItem(cartItem);

const quantity = ref();
syncRefs(itemQuantity, quantity);

const updateQuantity = async (quantity: number | undefined) => {
    if (quantity === itemQuantity.value) return;

    isLoading.value = true;

    await changeItemQuantity(Number(quantity));
    codeErrorsNotification();

    isLoading.value = false;
};
const debounceUpdate = useDebounceFn(updateQuantity, 800);

watch(quantity, () => debounceUpdate(quantity.value));

const removeCartItem = async () => {
    isLoading.value = true;
    await removeItem();
    isLoading.value = false;
    useGtmTrackingWithProduct(
        cartItem.value.price?.totalPrice,
        'remove_from_cart',
        [cartItem.value],
        { ecomm_pagetype: 'basket' }
    );
};

const colorOption = itemOptions.value.find(
    (option) => option.group === 'Farbe'
);
const sizeOption = itemOptions.value.find((option) => option.group === 'Größe');
</script>

<template>
    <div class="flex w-full gap-3 items-center">
        <RouterLink
            v-if="!isPromotion"
            :to="getProductRoute({ id: cartItem.payload.parentId })"
            class="aspect-square w-25 h-25 md:w-36 md:h-36 object-cover col-span-2 object-top"
        >
            <NuxtImg
                :src="
                    getSmallestThumbnailUrl(cartItem.cover) ||
                    cartItem.cover?.url
                "
                :alt="`${
                    cartItem.label || cartItem.payload.name || ''
                } cart item`"
                class="aspect-square w-25 h-25 md:w-36 md:h-36 object-cover col-span-2 object-top"
            />
        </RouterLink>
        <div class="flex flex-col w-full items-start justify-start gap-2">
            <div class="flex justify-between w-full">
                <RouterLink
                    :to="getProductRoute({ id: cartItem.payload.parentId })"
                >
                    <h3 class="text-xl font-bold text-left">
                        {{ cartItem.label }}
                        <span
                            v-if="isPromotion"
                            class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300"
                            >Promotion</span
                        >
                    </h3>
                </RouterLink>
                <SharedPrice :value="itemTotalPrice" />
            </div>
            <p class="text-gray-500 text-left">
                {{ colorOption?.group }}
                <span class="font-bold text-black px-2">
                    {{ colorOption?.option }}
                </span>
            </p>
            <div class="flex justify-between w-full">
                <p class="text-gray-500">
                    {{ sizeOption?.group }}
                    <span class="font-bold text-black px-2">
                        {{ sizeOption?.option }}
                    </span>
                </p>
                <SharedSpecialSelect
                    v-if="!isPromotion"
                    class="justify-self-center md:self-start cols-span-1 font-bold ![&>label]:text-base"
                    name="quantity"
                    v-model="quantity"
                    label="Anzahl"
                    is-optional
                    hide-please-select
                    :options="
                        [
                            ...Array(
                                cartItem.quantityInformation?.maxPurchase <= 10
                                    ? cartItem.quantityInformation?.maxPurchase
                                    : 10
                            ).keys(),
                        ].map((index) => index + 1)
                    "
                />
                <button
                    v-if="!isPromotion"
                    type="button"
                    class="font-medium text-brand-dark bg-transparent"
                    :class="{ 'text-gray-500': isLoading }"
                    @click="removeCartItem"
                    :title="$t('cart.removeArticle')"
                >
                    <span class="sr-only">{{ $t('cart.removeArticle') }}</span>
                    <i
                        aria-hidden
                        class="block icon i-custom-svg:trash text-grey text-xl"
                    ></i>
                </button>
            </div>
        </div>
    </div>
</template>
